@import url(https://fonts.googleapis.com/css?family=Roboto:300);
:root{
  --margin:10px;
  --padding:10px;
  --primary-color:blueviolet;
  --border-radius:5px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rtl{
  direction: rtl;
}

.display-flex{
  display: flex;
}

.display-block{
  display: block;
}

.display-inline{
  display: inline;
}

.center{
  justify-items: center;
  justify-content: center;
  display: flex;
  
}

.pointer{
  cursor: pointer;
}

.text-black{
  color: black;
}

.color-primary{
  color: blueviolet;
  color: var(--primary-color);
}

.background-lightyellow{
  background-color: lightyellow;
}

.margin{
  margin: 10px;
  margin: var(--margin);
}

.horizontal-margin{
  margin-left: 10px;
  margin-left: var(--margin);
  margin-right: 10px;
  margin-right: var(--margin);
}

.padding{
  padding: 10px;
  padding: var(--padding);
}

.circular-border{
  border-radius: 5px;
  border-radius: var(--border-radius);
  border-style: solid;
  border-color: blueviolet;
  border-color: var(--primary-color);
}

.justify-content-space-between{
  justify-content: space-between;
}

.width{
  width: --width;
}

.width-100-percent{
  width: 100%;
}
.App_App__1S6IR {
  text-align: center;
}

.App_section__1aUk8 {
  /* width: calc(100% - 225px); */
  /* margin-right: 225px; */
  /* width: 100%; */
  transition: all 0.5s ease;
}

.App_fixed__2wH0l {
  position: fixed;
  z-index: 2;
  top: 0;
}

.App_notification-container__2p7OI {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999
}

.App_top-right__2gor_ {
  top: 12px;
  right: 12px;
  transition: -webkit-transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  -webkit-animation: App_toast-in-right__NXJ3u .7s;
          animation: App_toast-in-right__NXJ3u .7s;
}

.App_bottom-right__1eCnR {
  bottom: 12px;
  right: 12px;
  transition: -webkit-transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  -webkit-animation: App_toast-in-right__NXJ3u .7s;
          animation: App_toast-in-right__NXJ3u .7s;
}

.App_top-left__3gIaQ {
  top: 12px;
  left: 12px;
  transition: -webkit-transform .6s ease-in;
  transition: transform .6s ease-in;
  transition: transform .6s ease-in, -webkit-transform .6s ease-in;
  -webkit-animation: App_toast-in-left__3QpHH .7s;
          animation: App_toast-in-left__3QpHH .7s;
}

.App_bottom-left__1vuGB {
  bottom: 12px;
  left: 12px;
  transition: -webkit-transform .6s ease-in;
  transition: transform .6s ease-in;
  transition: transform .6s ease-in, -webkit-transform .6s ease-in;
  -webkit-animation: App_toast-in-left__3QpHH .7s;
          animation: App_toast-in-left__3QpHH .7s;
}

.App_yellowBackground__2fv5C{
    background-color: yellow;
}
.SignIn_login-page__2e-v3 {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.SignIn_form__2Bs8p {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.SignIn_form__2Bs8p input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.SignIn_form__2Bs8p button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #4CAF50;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
}
.SignIn_form__2Bs8p button:hover,.SignIn_form__2Bs8p button:active,.SignIn_form__2Bs8p button:focus {
  background: #43A047;
}
.SignIn_form__2Bs8p .SignIn_message__28H6s {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.SignIn_form__2Bs8p .SignIn_message__28H6s a {
  color: #4CAF50;
  text-decoration: none;
}
.SignIn_form__2Bs8p .SignIn_register-form__2Qw6w {
  display: none;
}
.SignIn_container__2Y5Np {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.SignIn_container__2Y5Np:before, .SignIn_container__2Y5Np:after {
  content: "";
  display: block;
  clear: both;
}
.SignIn_container__2Y5Np .SignIn_info__3JarZ {
  margin: 50px auto;
  text-align: center;
}
.SignIn_container__2Y5Np .SignIn_info__3JarZ h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.SignIn_container__2Y5Np .SignIn_info__3JarZ span {
  color: #4d4d4d;
  font-size: 12px;
}
.SignIn_container__2Y5Np .SignIn_info__3JarZ span a {
  color: #000000;
  text-decoration: none;
}
.SignIn_container__2Y5Np .SignIn_info__3JarZ span .SignIn_fa__1TtIF {
  color: #EF3B3A;
}
.SignIn_body__9FPQw {
  background: #e7ffdb; /* fallback for old browsers */
  background: linear-gradient(to left, #e7ffdb, #e7ffdb);
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;      
}
 .Sidebar_sidebar__9vIdS {
     background: rgb(5, 68, 104);
     position: fixed;
     top: 0;
     width: 225px;
     height: 100%;
     padding: 20px 0;
     transition: all 0.5s ease;
     overflow-y: auto;
 }

 .Sidebar_sidebar__9vIdS .Sidebar_profile__1hLUO {
     margin-bottom: 30px;
     text-align: center;
 }

 .Sidebar_sidebar__9vIdS .Sidebar_profile__1hLUO img {
     display: block;
     width: 100px;
     height: 100px;
     border-radius: 50%;
     margin: 0 auto;
 }

 .Sidebar_sidebar__9vIdS .Sidebar_profile__1hLUO h3 {
     color: #ffffff;
     margin: 10px 0 5px;
 }
 .Navbar_top_navbar__2yhzl {
     background: rgb(7, 105, 185);
     /* height: 50px; */
     display: flex;
     flex-flow: wrap;
     align-items: center;
     padding: 5px 30px;
     justify-content: space-between;
 }

 .Navbar_hamburger__RWJbz {
     font-size: 28px;
     color: #f4fbff;
 }

 .Navbar_hamburger__RWJbz:hover {
     color: #a2ecff;
     cursor: pointer;
 }
 .SecondaryNavBar_secondary_navbar__2KCk5 {
     background: rgb(7, 105, 185);
     display: flex;
     justify-content: space-between;
     padding: 10px 30px;
     flex-flow: wrap;
 }

 .SecondaryNavBar_group__2z693 {
     display: flex;
     flex-flow: wrap;
     align-items: center;
 }
.Searchbar_search-box__2VBGs {
    height: 40px;
    border-radius: 40px;
    display: flex;
}

.Searchbar_search-box__2VBGs:hover {
    background-color: white;
}

.Searchbar_search-box__2VBGs:hover>.Searchbar_search-text__1bExb {
    width: 150px;
    padding: 0 6px;
}

.Searchbar_search-box__2VBGs:hover>.Searchbar_search-btn__2wffa {
    background: white;
    color: rgb(7, 105, 185);
}

.Searchbar_search-btn__2wffa {
    float: right;
    width: 40px;
    height: 100%;
    border-radius: 50%;
    background: rgb(7, 105, 185);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
    text-decoration: none;

}

/* .search-btn>i {
    fontsize: 30px;
} */

.Searchbar_search-text__1bExb {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: rgb(7, 105, 185);
    font-size: 16px;
    font-weight: normal;
    transition: 0.4s;
    line-height: 40px;
    width: 0px;
}

.Searchbar_search-options__3mgNX{
    position:absolute;
    z-index: 5;
    margin-top: 40px;
    padding: 0 40px;
    border-color: rgb(7, 105, 185);
    border-style: solid;
    border-width: 1px;
    box-shadow: 5px 10px 8px rgb(7, 105, 185);
    text-align: start;
    background-color: white;
}

/* .search-options:hover>.search-box {
    background-color: white;
}

.search-options:hover>.search-text {
    width: 150px;
    padding: 0 6px;
}

.search-options:hover>.search-btn {
    background: white;
    color: rgb(7, 105, 185);
} */
.Toast_notification-container__2UzAO {
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999999
}

.Toast_top-right__1QzH_ {
    top: 12px;
    right: 12px;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
    -webkit-animation: Toast_toast-in-right__3CPH9 .7s;
            animation: Toast_toast-in-right__3CPH9 .7s;
}

.Toast_bottom-right-fade__2k_WT,
.Toast_top-right-fade__3isxJ {
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
    -webkit-animation: Toast_toast-in-right-fade__1W-EL .7s;
            animation: Toast_toast-in-right-fade__1W-EL .7s;
    right: -385px;
}

.Toast_bottom-right__o5qdl {
    bottom: 12px;
    right: 12px;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
    -webkit-animation: Toast_toast-in-right__3CPH9 .7s;
            animation: Toast_toast-in-right__3CPH9 .7s;
}

.Toast_top-left__1ik__ {
    top: 12px;
    left: 12px;
    transition: -webkit-transform .6s ease-in;
    transition: transform .6s ease-in;
    transition: transform .6s ease-in, -webkit-transform .6s ease-in;
    -webkit-animation: Toast_toast-in-left__1WdoV .7s;
            animation: Toast_toast-in-left__1WdoV .7s;
}

.Toast_bottom-left-fade__VPwmo,
.Toast_top-left-fade__2P7Lp {
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
    -webkit-animation: Toast_toast-in-left-fade__37Gy1 .7s;
            animation: Toast_toast-in-left-fade__37Gy1 .7s;
    left: -385px;
}

.Toast_bottom-left__1lKwI {
    bottom: 12px;
    left: 12px;
    transition: -webkit-transform .6s ease-in;
    transition: transform .6s ease-in;
    transition: transform .6s ease-in, -webkit-transform .6s ease-in;
    -webkit-animation: Toast_toast-in-left__1WdoV .7s;
            animation: Toast_toast-in-left__1WdoV .7s;
}

.Toast_notification__iaxQL {
    background: #fff;
    transition: .3s ease;
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 30px;
    margin-bottom: 15px;
    width: 300px;
    max-height: 100px;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 0 10px #999;
    color: #000;
    opacity: .9;
    background-position: 15px;
    background-repeat: no-repeat;
}

.Toast_notification__iaxQL:hover {
    box-shadow: 0 0 12px #fff;
    opacity: 1;
    cursor: pointer
}

.Toast_notification-title__jxT-S {
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 6px;
    width: 300px;
    height: 18px;
}

.Toast_notification-message__sWfjM {
    margin: 0;
    text-align: left;
    height: 18px;
    margin-left: -1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Toast_notification-image__33oLb {
    float: left;
    margin-right: 15px;
}

.Toast_notification-image__33oLb .Toast_img__1GOTw {
    width: 30px;
    height: 30px;
}

.Toast_toast__RQPn0 {
    width: 365px;
    color: #fff;
    padding: 20px 15px 10px 10px;
}

.Toast_button__29mIo {
    position: relative;
    right: -.3em;
    top: -.3em;
    float: right;
    font-weight: 700;
    color: #fff;
    outline: none;
    border: none;
    text-shadow: 0 1px 0 #fff;
    opacity: .8;
    line-height: 1;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0
}

@-webkit-keyframes Toast_toast-in-right__3CPH9 {
    from {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);

    }

    to {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes Toast_toast-in-right__3CPH9 {
    from {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);

    }

    to {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@-webkit-keyframes Toast_toast-in-right-fade__1W-EL {
    from {
        -webkit-transform: translateX();
                transform: translateX();

    }

    to {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}

@keyframes Toast_toast-in-right-fade__1W-EL {
    from {
        -webkit-transform: translateX();
                transform: translateX();

    }

    to {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}

@-webkit-keyframes Toast_toast-in-left__1WdoV {
    from {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);

    }

    to {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes Toast_toast-in-left__1WdoV {
    from {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);

    }

    to {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@-webkit-keyframes Toast_toast-in-left-fade__37Gy1 {
    from {
        -webkit-transform: translateX(0);
                transform: translateX(0);

    }

    to {
        -webkit-transform: translateX(-100);
                transform: translateX(-100);
    }
}

@keyframes Toast_toast-in-left-fade__37Gy1 {
    from {
        -webkit-transform: translateX(0);
                transform: translateX(0);

    }

    to {
        -webkit-transform: translateX(-100);
                transform: translateX(-100);
    }
}

.Toast_success__14Gyw {
    background-color: #5cb85c;
}
.Loading_html-progress__jg5c6 {
    background-color: lightgray;
    width: 100%;
    height: 4px;
}

.Loading_progress-value__3W9rt {
    background-color: rgb(5, 68, 104);
    height: 100%;
    -webkit-animation: Loading_grow__cphP8 1s linear forwards;
            animation: Loading_grow__cphP8 1s linear forwards;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

/* animation */
@-webkit-keyframes Loading_grow__cphP8 {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}
@keyframes Loading_grow__cphP8 {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}
